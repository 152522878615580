import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import {logOut} from 'api/auth/index'
import { useHistory } from "react-router-dom";
//redux

const Logout = props => {
  let history = useHistory();
  useEffect(() => {
    logOut()
    history.push("/login")
  }, [])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
