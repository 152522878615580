import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Charts
import LineBar from "./Chart1"

const EChart = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>E Chart | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Charts" breadcrumbItem="E Chart" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle>Mix Line-Bar</CardTitle>
                  <div id="mix-line-bar" className="e-chart">
                    <LineBar />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default EChart
