
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle
} from "reactstrap"
import Pagination from '@mui/material/Pagination';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CustomTable from "../../../components/custom/table/CustomTable"

import { GetListData } from "api/Table2";
import {useGetListDataCount} from "../../../hook/dataTable"
import TableHeaderRow from "./TableHeaderItems"

const DatatableTables = () => {
  const [data,setData] = useState([])
  const [dataSize, setDataSize] = useState(20)
  const [startpoint,setstartpoint] = useState(0)
  const {data:count} = useGetListDataCount()
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true)
  useEffect(()=>{
    setLoading(true)
    const peyload={
      start:startpoint ===0 ? 0 :(startpoint-1) * dataSize ,
      size:dataSize
    }
    GetListData(peyload)
      .then(t=>{
          setLoading(false)
          setData(t.data.content)
      }).catch(c=>{
          setLoading(false)
      })
  },[dataSize,startpoint])

  const handleChange = (event, value) => {
    setPage(value);
    setstartpoint(value)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Basic Tables | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Basic Tables" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle>Basic example</CardTitle>
                  <CardSubtitle className="mb-3">
                    For basic styling—light padding and only horizontal
                    dividers—add the base className <code>.table</code> to any
                    <code>&lt;table&gt;</code>.
                  </CardSubtitle>
                  <CustomTable
                    header={TableHeaderRow}
                    _data={data}
                    loading={loading}
                  />
                  <Row className="mt-3 justify-content-between">
                  <Col lg="1">
                      <select value={dataSize} onChange={e=> setDataSize(e.target.value)} className="form-control">
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </Col>

                    <Col lg="6">
                      <Pagination
                        color="primary"
                        shape="rounded" 
                        count={Math.ceil(count/dataSize)} 
                        boundaryCount={2} 
                        page={page}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables