import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SubMenuComponent from "./subMenu"

import {useUserData} from "hook/userData"



const SidebarContent = props => {
  const ref = useRef();
  const {data:user} = useUserData()


  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  const SideBarItems =[
    {
      icon:"bx bx-home-circle",
      text:"Dashbaord",
      authority:["user-basic"],
      link:"/dashboard",
      child:null
    },{
      icon:"bx bx-home-circle",
      text:"Services",
      authority:["user-basic"],
      link:"/#",
      child:[
        {
          icon:"bx bx-home-circle",
          text:"Candlestick",
          authority:["user-basic"],
          link:"/candlestick",
          child:null
        },{
          icon:"bx bx-home-circle",
          text:"Supply and Demand",
          authority:["user-basic"],
          link:"/supplyanddemand",
          child:null
        }
      ]
    },{
      icon:"bx bx-home-circle",
      text:"VIP Servies",
      authority:["user-basic"],
      link:"/#",
      child:[
        {
          icon:"bx bx-home-circle",
          text:"Hero",
          authority:["user-basic"],
          link:"/hero",
          child:null
        }
      ]
    },
    {
      icon:"bx bx-home-circle",
      text:"Messages",
      authority:["user-basic"],
      link:"/email-inbox",
      child:null
    },
    {
      icon:"bx bx-home-circle",
      text:"Peyments",
      authority:["user-basic"],
      link:"/#",
      child:[
        {
          icon:"bx bx-home-circle",
          text:"List",
          authority:["user-basic"],
          link:"/payments",
          child:null
        }
      ]
    },
    {
      icon:"bx bx-home-circle",
      text:"Help",
      authority:["user-basic"],
      link:"/#",
      child:[
        {
          icon:"bx bx-home-circle",
          text:"Faq",
          authority:["user-basic"],
          link:"/faq",
          child:null
        },{
          icon:"bx bx-home-circle",
          text:"Videos",
          authority:["user-basic"],
          link:"/videos",
          child:null
        }
      ]
    },
    {
      icon:"bx bx-home-circle",
      text:"Others",
      authority:["user-basic"],
      link:"/#",
      child:[
        {
          icon:"bx bx-home-circle",
          text:"Table 1",
          authority:["user-basic"],
          link:"/table-1",
          child:null
        },{
          icon:"bx bx-home-circle",
          text:"Table 2",
          authority:["user-basic"],
          link:"/table-2",
          child:null
        },{
          icon:"bx bx-home-circle",
          text:"Table 3",
          authority:["user-basic"],
          link:"/table-3",
          child:null
        },{
          icon:"bx bx-home-circle",
          text:"Form",
          authority:["user-basic"],
          link:"/form",
          child:null
        },{
          icon:"bx bx-home-circle",
          text:"Chart",
          authority:["user-basic"],
          link:"/chart",
          child:null
        }
      ]
    }

  ]

  const isAuthority=(route)=>{
    let Alowd = false;
    if(route && user){
      user.data.content.user.authority.forEach(element => {
        if(route.includes(element)){
          Alowd = true;
        }
      });
    }
    return Alowd;
  }
  
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {SideBarItems.map((value,index)=>{   
        
              return <li className={!isAuthority(value.authority)?"d-none":""} key={index}>
                        <Link to={value.link} className={value.child?"has-arrow":""}>
                          <i className={value.icon}></i>
                          <span>{props.t(value.text)}</span>
                        </Link>
                        {value.child && <SubMenuComponent data={value.child}/>}
                      </li>
              })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
