
import React,{useEffect, useMemo, useState} from 'react'
import { Table , Spinner } from "reactstrap"
import PropTypes from "prop-types";
import DeleteModal from "components/Common/DeleteModal";
import EditItems from "components/Common/EditModal"
import { Link } from "react-router-dom";
import {DeleteListData} from 'api/Table2/index'
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const CustomTable=({header, _data, loading, ActiveDelete=false, ActiveEdit = false,update})=>{
    const [selectedFilterRow,setSelectedFilterRow] = useState("")
    const [data, setData] = useState(_data)
    const [deleteModal, setDeleteModal] = useState(false);
    const [Contact, setContact] = useState('')

    useMemo(()=>{
        if(selectedFilterRow){
            setData(data.sort((a, b) => (a[selectedFilterRow] > b[selectedFilterRow]) ? 1 : -1))
        }
    },[selectedFilterRow])

    useEffect(()=>{
        setData(_data)
    },[_data])

    const onClickDelete = (id) => {
        setContact(id);
        setDeleteModal(true);
      };
    
      const handleDeleteUser = () => {
        DeleteListData({id:Contact})
            .then(t=>{
                toastr.success("Deleted successfully", "Delete !");
                setDeleteModal(false);
                update()
            }).catch(c=>{
                toastr.success("Error", "Delete !");
                setDeleteModal(false);
            })        
      };

    if(loading){
        return <div className='d-flex justify-content-center align-items-center'>
            <Spinner className="ms-2 mb-5 mt-5" color="info" />
        </div>
    }

    return <div className="table-responsive">
              <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setDeleteModal(false)}
                />
        <Table className="table mb-0">
    <thead>
      <tr>
        {header.map((value,index)=> {
            return <th 
                    key={index} 
                    className="cursorPointer"
                    onClick={e=> setSelectedFilterRow(value.dataField)}
                >
                    {value.text} 
                <i className={`bx bx-sort-${selectedFilterRow===value.dataField?"down":"up"} p-1`}></i>
            </th>
        })}
        {ActiveEdit && <th className='text-center'>Edit</th>}
        {ActiveDelete && <th className='text-center'>Delete</th>} 

      </tr>
    </thead>
    <tbody>
        {data.map((value,index)=>{
            return <tr key={index}>
                {header.map((valueitem,index)=>{
                    return <td key={index}>{value[valueitem.dataField]} </td>
                })}
                
                {ActiveEdit && <td className='text-center'>
                        <EditItems
                            _data={value}
                            update={update}
                        />
                    </td>}

                {ActiveDelete && <td className='text-center'> 
                <Link className="text-danger" to="#">
                        <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => onClickDelete(value.id)}
                        ></i>
                    </Link>
                </td>} 
          </tr>
        })}
    </tbody>
    </Table>
  </div>
}
export default CustomTable


CustomTable.propTypes = {
    header: PropTypes.array,
    _data: PropTypes.array,
    loading: PropTypes.bool,
    ActiveDelete:PropTypes.bool,
    ActiveEdit:PropTypes.bool,
    update:PropTypes.func
};

