import { useQuery } from "react-query"
import {GetListDataCount, GetListCategory} from 'api/Table2/index'

const useGetListDataCount = () => {
    return useQuery("GetListDataCount", GetListDataCount)
}

const useGetListCategory = () => {
    return useQuery("GetListCategory", GetListCategory)
}
  
export {useGetListDataCount, useGetListCategory}