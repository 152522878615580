import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Table1 from "../pages/Dashboard/table1/index"
import Table2 from "../pages/Dashboard/table-2/index"
import Table3 from "../pages/Dashboard/table-3/index"

import FormElements from "../pages/Dashboard/form/index"
import chart from "../pages/Dashboard/Charts"
import Ticket from '../pages/Dashboard/Email/email-inbox'
import TicketRead from '../pages/Dashboard/Email/email-read'

// Services
import Candlestick from "../pages/Dashboard/Services/Candlestick/index"

//Error pages 
import Pages404 from "pages/ErrorPages/pages-404"

const authProtectedRoutes = [

  // Dashboard
  { path: "/dashboard", component: Dashboard , authority:["user-basic"] },

  // Services
  { path: "/candlestick", component: Candlestick , authority:["user-basic"] },

  // VIP Services
  // authorities
  { path: "/profile", component: UserProfile , authority:["user-basic"] },
  { path: "/table-1", component: Table1 , authority:["user-basic"] },
  { path: "/table-2", component: Table2 , authority:["user-basic","pro-basic"] },
  { path: "/table-3", component: Table3 , authority:["user-basic"] },
  { path: "/form", component: FormElements , authority:["user-basic"] },
  { path: "/chart", component: chart , authority:["user-basic"] },

  { path: "/email-inbox", component: Ticket, authority:["user-basic"]},
  { path: "/email-read", component: TicketRead, authority:["user-basic"] },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
