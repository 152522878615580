import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"

import {register} from 'api/auth'

import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Include Both Helper File with needed methods

// initialize relavant method of both Auth

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  register(user)
        .then(t=>{
          toastr.success("Register successfully", "Register !");
          history.push("/login");
        }).catch(c=>{
          if(c.response){
            if(c.response.data.message =="Unauthorized")
            {
              toastr.error("Email or Password is wrong !", "Register !");
            }else{{
              toastr.error(c.response.data.message, "Register !");
            }}
          }
        })
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
