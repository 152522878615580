import usFlag from "../assets/images/flags/us.jpg"
import iranFlaq from "../assets/images/flags/iran-flaq.webp"


const languages = {
  fa: {
    label: "فارسی",
    flag: iranFlaq,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
