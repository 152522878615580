import BASE_AXIOS from "api/BaseAxios";

const PREFIX                = '/services/candlestick'
const GetListData           = async (data)  => await BASE_AXIOS().post(PREFIX + '/all', data)
//const GetListDataSearch     = async (data)  => await BASE_AXIOS().post(PREFIX + '/search', data)
const GetListDataCount      = async ()      => await (await BASE_AXIOS().get(PREFIX + '/count')).data.content.size
//const GetListCategory       = async ()      => await (await BASE_AXIOS().get(PREFIX + '/category')).data
//const InsertListData        = async (data)  => await BASE_AXIOS().post(PREFIX + '/insert', data)
//const UpdateistData         = async (data)  => await BASE_AXIOS().post(PREFIX + '/update', data)
//const DeleteListData        = async (data)  => await BASE_AXIOS().post(PREFIX + '/delete', data)

export {GetListData, GetListDataCount}

