import PropTypes from 'prop-types'
import React,{useEffect} from "react"

import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import Pages403 from 'pages/ErrorPages/pages-403'
import Pages404 from 'pages/ErrorPages/pages-404'

import PagesMaintenance from 'pages/ErrorPages/pages-maintenance'

import {useUserData} from "hook/userData"

import Dashboard from "./pages/Dashboard/index"


// Import scss

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

//import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
//fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  const {data:user} = useUserData()
  useEffect(()=>{
    const selected = localStorage.getItem("i18nextLng")
    if(selected==="fa"){
      document.querySelector("html").setAttribute("data-lang-dir", "RTL")
      return
    }
    document.querySelector("html").setAttribute("data-lang-dir", "LTR")
  },[])

  useEffect(()=>{
    const selected = localStorage.getItem("themeColor")
    if(selected==="dark"){
      document.querySelector("html").setAttribute("theme-color", "DARK")
      import("./assets/scss/theme-dark.scss")
      return
    }
    import("./assets/scss/theme.scss")
    document.querySelector("html").setAttribute("theme-color", "LIGHT")
  },[])


  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const isAuthority=(route)=>{
    let Alowd = false;
    if(route && user){
      user.data.content.user.authority.forEach(element => {
        if(route.includes(element)){
          Alowd = true;
        }
      });
    }
    return Alowd;
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

              <Authmiddleware
                path={"/dashboard"}
                layout={Layout}
                component={Dashboard}
                isAuthProtected={true}
                exact
              />
            {user && authProtectedRoutes.map((route, idx) => {
              if(!isAuthority(route.authority)){
                return
              }
              return <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            })}
            <Route exact path="/maintenance" component={PagesMaintenance}/>
            <Route exact path="/forbidden" component={Pages403}/>
            <Route path="*" component={Pages404}/>
          </Switch>
        </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
