const TableHeaderRow = [ 
    {
    dataField: "id",
    text: "Id",
  }, {
    dataField: "name",
    text: "Name",
  }, {
    dataField: "position",
    text: "Position",
  }, {
    dataField: "age",
    text: "Age",
  }, {
    dataField: "salary",
    text: "Salary",
  }, {
    dataField: "started_at",
    text: "Start at",
  }
]

export default TableHeaderRow