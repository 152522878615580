
import React, { useCallback, useEffect, useMemo, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  InputGroup
} from "reactstrap"
import Pagination from '@mui/material/Pagination';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Select from "react-select";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CustomTable from "../../../components/custom/table/CustomTable"

import { GetListDataSearch } from "api/Table2";
import {useGetListDataCount, useGetListCategory} from "../../../hook/dataTable"
import TableHeaderRow from "./TableHeaderItems"


const DatatableTables = () => {

  const [data,setData] = useState([])
  const [dataSize, setDataSize] = useState(20)
  const [startpoint,setstartpoint] = useState(0)
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true)
  const [jobList, setJobList] = useState([])
  const [search, setSearch] = useState("")
  const [searchResualt, setSearchResualt] = useState([])

  //filter state
  const [name, setName] = useState('')
  const [age1, setAge1] = useState(null)
  const [age2, setAge2] = useState(null)
  const [salary1, setSalary1] = useState(null)
  const [salary2, setSalary2] = useState(null)
  const [isEnable, setIsEnable] = useState(false)
  const [dateTime1, setDateTime1] = useState(null)
  const [dateTime2, setDateTime2] = useState(null)
  const [selectedMulti2, setselectedMulti2] = useState(null);
  const [lastFilter,setLastFilter] = useState({})
  //filter state
  
  const {data:count} = useGetListDataCount()
  const {data:jobs} = useGetListCategory()

  useMemo(()=>{
    setJobList(
      jobs?.content.map((value, index)=>{
        return { label: value, value: value }
      })
    );
  },[jobs])

  useMemo(()=>{
    setSearchResualt(
      data.filter((value)=> value.name.includes(search) || value.position.includes(search) )
    )
  },[search])


  const Peyloads=()=>{
    const peyload={
      start:startpoint ===0 ? 0 :(startpoint-1) * dataSize ,
      size:dataSize,
      name,
      position: selectedMulti2?.map(value=> value.label).join("|"),
      age1,
      age2,
      salary1,
      salary2,
      enable: isEnable? 1 : 0
    }
    if(dateTime1){
      peyload["started_at1"]=dateTime1;
    }
    if(dateTime2){
      peyload["started_at2"]=dateTime2;
    }
    return peyload
  }

  const FetchApi = ()=>{
    if(JSON.stringify(Peyloads()) === JSON.stringify(lastFilter))
    {
      return
    }
    setLoading(true)
    GetListDataSearch(Peyloads())
      .then(t=>{
          setLoading(false)
          setLastFilter(Peyloads())
          setData(t.data.content)
      }).catch(c=>{
          setLoading(false)
      })
  }

  useEffect(FetchApi,[dataSize,startpoint])

  const handleMulti2 =(selectedMulti2)=> {
    setselectedMulti2(selectedMulti2);
  }
  
  const handleChange = (event, value) => {
    setPage(value);
    setstartpoint(value)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Basic Tables | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Basic Tables" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle>Basic example</CardTitle>
                  <CardSubtitle className="mb-3">
                    For basic styling—light padding and only horizontal
                    dividers—add the base className <code>.table</code> to any
                    <code>&lt;table&gt;</code>.
                  </CardSubtitle>
                  <Row>
                    <Col className="mb-2" lg="6" sm="12">
                      <Label>Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          onChange={(a,b,c)=>{
                            const date = b.split("to")
                            if(date[0]){
                              setDateTime1(data[0])
                            }
                            if(date[1]){
                              setDateTime2(data[1])
                            }
                          }}
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d H:m:ss"
                          }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                      </InputGroup>
                    </Col>

                    <Col className="mb-2" lg="6" sm="12">
                    <Label>name</Label>

                      <InputGroup>
                        <input
                          className="form-control d-block"
                          type="text"
                          value={name}
                          onChange={e=> setName(e.target.value)}
                        />
                      </InputGroup>
                    </Col>

                    <Col className="mb-2" lg="6" sm="12">
                    <Label>Range age</Label>

                      <InputGroup>
                        <input
                          className="form-control d-block"
                          type="number"
                          value={age1}
                          max={100}
                          min={0}
                          onChange={e=> setAge1(e.target.value)}
                        />
                        <input
                          className="form-control d-block"
                          type="number"
                          value={age2}
                          max={100}
                          min={0}
                          onChange={e=> setAge2(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    
                    <Col className="mb-2" lg="6" sm="12">
                      <Label>Range salary</Label>
                      <InputGroup>
                        <input
                          className="form-control d-block"
                          type="number"
                          value={salary1}
                          min={0}
                          onChange={e=> setSalary1(e.target.value)}
                        />
                        <input
                          className="form-control d-block"
                          type="number"
                          min={0}
                          value={salary2}
                          onChange={e=> setSalary2(e.target.value)}
                        />
                      </InputGroup>
                    </Col>

                    <Col className="mb-2" lg="6" sm="12">
                          <Label>Job list</Label>
                          <Select
                            value={selectedMulti2}
                            isMulti={true}
                            onChange={(value) => {
                              handleMulti2(value);
                            }}
                            options={jobList}
                            classNamePrefix="select2-selection"
                            isLoading={jobs === undefined}
                          />
                    </Col>
                    <Col className="form-check form-switch form-switch-md mb-3 ml-5" style={{marginTop:"33px"}} lg="3" sm="12">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            value={isEnable}
                            onChange={e=> setIsEnable(e.target.value)}                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizemd"
                          >
                            is enable
                          </label>

                         
                    </Col>

                    <Col className="align-items-center mb-3 ml-5 d-flex justify-content-center" style={{marginTop:"30px"}} lg="3" sm="12">
                          <Button
                            color="primary"
                            className="btn btn-primary w-100"
                            onClick={FetchApi}
                          >
                            Filter
                          </Button>
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col className="mb-2" lg="6" sm="12">
                      <Label>Search</Label>
                    
                      <InputGroup>
                        <input
                          className="form-control d-block"
                          type="text"
                          value={search}
                          onChange={e=> setSearch(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <CustomTable
                    header={TableHeaderRow}
                    _data={search === "" ? data : searchResualt}
                    loading={loading}
                  />
                  <Row className="mt-3 justify-content-between">
                  <Col lg="1">
                      <select value={dataSize} onChange={e=> setDataSize(e.target.value)} className="form-control">
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </Col>

                    <Col lg="6">
                      <Pagination
                        color="primary"
                        shape="rounded" 
                        count={Math.ceil(count/dataSize)} 
                        boundaryCount={2} 
                        page={page}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables