import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const BASE_URL = "https://serv01.amon.trade/api"

const BASE_AXIOS =()=>{
    return axios.create({
        baseURL:BASE_URL,
        headers: {
            Authorization:localStorage.getItem("authUser")?`Bearer ${localStorage.getItem("authUser")}`:null,
        },
        validateStatus: function(status) {
            if (status === 401 && location.pathname!=="/login") {
                document.location.replace("/login");
                localStorage.removeItem("authUser");
            }
            if (status === 403) {
              document.location.replace("/forbidden");
              localStorage.removeItem("authUser");
            }
            if (status === 503) {
                document.location.replace("/maintenance");
            }
            return status >= 200 && status < 300; 
        }
    
    })
}

export default BASE_AXIOS
