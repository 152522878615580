const TableHeaderRow = [ 
    {
    dataField: "id",
    text: "ID",
  }, {
    dataField: "symbol",
    text: "Symbol",
  }, {
    dataField: "pattern",
    text: "Pattern",
  }, {
    dataField: "date",
    text: "Date",
  }
]

export default TableHeaderRow