import BASE_AXIOS from "api/BaseAxios";

const GetListData = async (data) => await BASE_AXIOS().post('/datatable/all', data)
const GetListDataSearch = async (data) => await BASE_AXIOS().post('/datatable/search', data)
const GetListDataCount = async () => await (await BASE_AXIOS().get('/datatable/count')).data.content.size
const GetListCategory = async () => await (await BASE_AXIOS().get('/datatable/category')).data
const InsertListData = async (data) => await BASE_AXIOS().post('/datatable/insert', data)
const UpdateistData = async (data) => await BASE_AXIOS().post('/datatable/update', data)
const DeleteListData = async (data) => await BASE_AXIOS().post('/datatable/delete', data)

export {GetListData, GetListDataCount, GetListCategory, GetListDataSearch, InsertListData,DeleteListData,UpdateistData}

