import React,{useState,useMemo} from 'react'
import {
    Modal,
    Button,
    Row,
    Col,
    Input,
    Label,
    Form,
    FormFeedback,
    InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {useGetListCategory} from "../../hook/dataTable"
import Select from "react-select";
import {UpdateistData} from 'api/Table2/index'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

const EditItems=({_data,update})=>{
    const [modal_standard, setmodal_standard] = useState(false);
    const [jobList, setJobList] = useState([])
    const [selectedGroup, setselectedGroup] = useState(null);
    const [dateTime, setDateTime] = useState(_data.started_at)
    const [position, setPosition] = useState(_data.position)
    const {data:jobs} = useGetListCategory()
    const [isEnable, setIsEnable] = useState(_data.enable)
    const [positionFullvalue, setPositionFullvalue] = useState({ label: _data.position, value: _data.position })
    const [dateTimeFullValue, setDateTimeFullValue] = useState(new Date(_data.started_at))


    useMemo(()=>{
        setJobList(
          jobs?.content.map((value, index)=>{
            return { label: value, value: value }
          })
        );
    },[jobs])

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: _data.name,
            age: _data.age,
            salary: _data.salary,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
            "This value is required"
            ),
            age: Yup.number().max(99,"The age must be at least 99.").min(16,"The age must be at least 16.").required(
                "This value is required"
            ),
            salary: Yup.number().required(
                "This value is required"
            ),
            started_at: Yup.string(),
            position: Yup.string(),
        }),
        onSubmit: (values, { resetForm }) => {
          let data = values;
          data["position"] = position;
          data["started_at"] = dateTime;
          data["enable"]=isEnable? 1 : 0;
          data["id"]=_data.id;

          if(!dateTime){
            toastr.error("Choose start date", "insert");
            return
          }
          if(!position){
            toastr.error("Choose position", "insert");
            return
          }
          UpdateistData(data)
            .then(t=>{
              toastr.success("Inserted Successfully", "insert");
               tog_standard();
               setDateTime("")
               setPosition("")
               update();
               resetForm()
            }).catch(c=>{
              toastr.error("insert failed ", "Login !");
            })
        }
      });

    return <React.Fragment>
                      <Link className="text-success" to="#">
                        <i
                        className="mdi mdi-pencil font-size-18"
                        id="deletetooltip"
                        onClick={() => tog_standard()}
                        ></i>
                    </Link>
                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                        Add items
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <Form
                            onSubmit={(e) => {
                            e.preventDefault();
                            validationType.handleSubmit();
                            return false;
                            }}>
                        <Row>
                            <Col className='mb-2' lg={12} sm={12}>
                                <Label className="form-label">Name</Label>
                                <Input
                                    name="name"
                                    placeholder="Name"
                                    type="text"
                                    onChange={validationType.handleChange}
                                    onBlur={validationType.handleBlur}
                                    value={validationType.values.name || ""}
                                    invalid={
                                    validationType.touched.name && validationType.errors.name ? true : false
                                    }
                                />
                                {validationType.touched.name && validationType.errors.name ? (
                                    <FormFeedback type="invalid">{validationType.errors.name}</FormFeedback>
                                ) : null}
                            </Col>

                            <Col className='mb-2' lg={6} sm={12}>
                                <Label className="form-label">Age</Label>
                                <Input
                                    name="age"
                                    placeholder="Age"
                                    type="number"
                                    onChange={validationType.handleChange}
                                    onBlur={validationType.handleBlur}
                                    value={validationType.values.age || ""}
                                    invalid={
                                    validationType.touched.age && validationType.errors.age ? true : false
                                    }
                                />
                                {validationType.touched.age && validationType.errors.age ? (
                                    <FormFeedback type="invalid">{validationType.errors.age}</FormFeedback>
                                ) : null}
                            </Col>

                            <Col className='mb-2' lg={6} sm={12}>
                                <Label className="form-label">Salary</Label>
                                <Input
                                    name="salary"
                                    placeholder="Salary"
                                    type="number"
                                    onChange={validationType.handleChange}
                                    onBlur={validationType.handleBlur}
                                    value={validationType.values.salary || ""}
                                    invalid={
                                    validationType.touched.salary && validationType.errors.salary ? true : false
                                    }
                                />
                                {validationType.touched.salary && validationType.errors.salary ? (
                                    <FormFeedback type="invalid">{validationType.errors.salary}</FormFeedback>
                                ) : null}
                            </Col>
                            <Col className='mb-2' lg={12} sm={12}>
                                <Label>Start at</Label>
                                <Flatpickr
                                    className="form-control d-block"
                                    onChange={(a,b,c)=>{
                                        setDateTime(b)
                                        setDateTimeFullValue(a)
                                    }}
                                    name="started_at"
                                    type="text"
                                    value={dateTimeFullValue}
                                    options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d H:i:ss"
                                    }}
                                    />
                            </Col>
                            <Col className='mb-2' lg={12} sm={12}>
                            <Label>Position</Label>
                                <Select
                                    name="position"
                                    value={positionFullvalue}
                                    onChange={(a)=>{
                                        setPosition(a.value)
                                        setPositionFullvalue(a)
                                    }}
                                    options={jobList}
                                    classNamePrefix="select2-selection"
                                />
                            </Col>

                            <Col lg="6" sm="12">
                                <div className="form-check form-switch form-switch-md d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizemd"
                                    value={isEnable}
                                    onChange={e=> setIsEnable(e.target.value)}                            
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizemd"
                                >
                                    is enable
                                </label>  
                                </div>  
                            </Col>


                        </Row>
                        </Form>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard();
                          }}
                          className="btn btn-secondary "
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={e=>{
                            e.preventDefault();
                            validationType.handleSubmit();
                          } }
                        >
                          Save changes
                        </button>
                      </div>
                    </Modal>
    </React.Fragment>
}

export default EditItems

EditItems.propTypes = {
    update: PropTypes.func,
    _data: PropTypes.array,
};

