import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from "prop-types"
import {useUserData} from "hook/userData"

const SubMenuComponent=({data})=>{
  const {data:user} = useUserData()

  const isAuthority=(route)=>{
    let Alowd = false;
    if(route && user){
      user.data.content.user.authority.forEach(element => {
        if(route.includes(element)){
          Alowd = true;
        }
      });
    }
    return Alowd;
  }

    return <ul className="sub-menu" aria-expanded="false">
      {data.map((value,index)=>{
        return <li className={!isAuthority(value.authority)?"d-none":""} key={index}>
        <Link to={value.link}>{value.text}</Link>
      </li>
      })}
         
    </ul>
  }

export default SubMenuComponent

SubMenuComponent.propTypes = {
    data: PropTypes.array,
}